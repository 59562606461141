import apiHandler from '@/utils/handlers/ApiHandler';
import apiHeader from '@/utils/handlers/ApiHeader';
import {useAdminsStore} from '@/store/admins'

export const apiActions = {
  /** Get admins **/
  fetchAdmins(params) {
    const store = useAdminsStore();

    return apiHandler.get('cms-user/list', params).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        store.setAdmins(response.data);
        return true;
      }
      return false;
    }).catch(error => {
      return false
    });
  },

  /** Get admin details **/
  fetchAdminDetails(id) {
    const store = useAdminsStore();

    return apiHandler.get(`cms-user/${id}`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        store.setAdminDetails(response.data);
        return true;
      }
      return false;
    }).catch(error => {
      return false
    });
  },

  /** Create admin **/
  createAdmin(data) {
   return apiHandler.post('cms-user/create', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      return false
    });
  },

  /** Updated exisiting admins details **/
  updateAdminDetails(id, data) {
    return apiHandler.put(`cms-user/${id}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      return false
    });
  },

  /** Delete admin **/
  deleteAdmin(id) {
    return apiHandler.delete(`cms-user/${id}`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      return false
    });
  },

  /** Activate new admin **/
  activateAdmin(id, token, data) { 
    

    return apiHandler.post(`cms-user/activate/${id}/${token}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      return false
    });
  }
}
