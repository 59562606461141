import AuthGuard from '@/router/guards/auth'

const ManagerLayout = () => import('@/views/Layout.vue')
const ListingsOverview = () => import('@/views/listings/Overview.vue')
const ListingsDetails = () => import('@/views/listings/Details.vue')

export const ROUTE_NAMES_LISTINGS = Object.freeze({
  OVERVIEW: 'ListingsOverview',
  DETAILS: 'ListingsDetails'
});

export const listings = {
  path: '/listings',
  meta: {
    guard: AuthGuard
  },
  component: ManagerLayout,
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_LISTINGS.OVERVIEW,
      component: ListingsOverview
    },
    {
      path: 'details/:id',
      name: ROUTE_NAMES_LISTINGS.DETAILS,
      component: ListingsDetails
    }
  ]
}