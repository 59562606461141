import InvalidCredentials from './errorList/InvalidCredentials'
import Default from './errorList/Default'
import {ERROR_CODES} from './errorCodes'

const DEFAULT_LANG = 'en';

const errors = {
  [ERROR_CODES.INVALID_CREDENTIALS]: new InvalidCredentials(),
  [ERROR_CODES.DEFAULT]: new Default(),
}

class ErrorHandler {
  constructor() {
    this.selectedLanguage = DEFAULT_LANG;
  }

  setLanguage(lang = DEFAULT_LANG) {
    this.selectedLanguage = lang;
  }

  getError(errorObj) {
    const { error } = errorObj;
    return errors[error] ? errors[error] : errors['default'];
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ErrorHandler();
    }
    return this.instance;
  }
}

export default ErrorHandler.getInstance();
