import BaseGuard from "@/router/guards/base";
import AuthenticationManager from "@/utils/authenticationManager/AuthenticationManager";

// TODO: Update the type based on the project's configuration.
const TYPE_ADMIN = 3;

class AdminGuard extends BaseGuard {
  constructor() {
    super();
    this.typeAllowed = TYPE_ADMIN;
  }

  async handleRouteEnter() {
    if (!AuthenticationManager.providerInstance || !this.checkUserType()) {
      return this.routeOnFailure;
    }

    // User is authenticated AND an admin.
    if (AuthenticationManager.isAuthenticated && this.checkUserType()) {
      return new Promise(resolve => resolve(true));
    }

    // The token needs to be refreshed.
    const refreshed = await AuthenticationManager.refreshTokens();
    return refreshed ? true : this.routeOnFailure;
  }

  checkUserType() {
    // TODO: Update this implementation based on the project's configuration.
    const activeUser = {}; // AuthenticationManager.authenticatedUserType();
    return !!activeUser && activeUser.type === this.typeAllowed;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AdminGuard();
    }
    return this.instance;
  }
}

export default AdminGuard.getInstance();
