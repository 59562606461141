import AuthGuard from '@/router/guards/auth'
import AdminGuard from '@/router/guards/admin'

const ManagerLayout = () => import('../../views/Layout.vue')
const Users = () => import('../../views/users/VerifiedOverview.vue')
const UserDetails = () => import('../../views/users/Details.vue')
const UserWithdrawals = () => import('../../views/users/Withdrawals.vue')

export const ROUTE_NAMES_USERS = Object.freeze({
  OVERVIEW: 'UsersOverview',
  DETAILS: 'UserDetails',
  WITHDRAWALS: 'UserWithdrawalsList',
});

export const users = {
  path: '/users',
  meta: {
    guard: AuthGuard
  },
  component: ManagerLayout,
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_USERS.OVERVIEW,
      meta: {
        // guard: AdminGuard
      },
      component: Users
    },
    {
      path: 'details/:id',
      name: ROUTE_NAMES_USERS.DETAILS,
      meta: {
        // guard: AdminGuard
      },
      component: UserDetails
    },
    {
      path: 'withdrawals',
      name: ROUTE_NAMES_USERS.WITHDRAWALS,
      meta: {
        // guard: AdminGuard
      },
      component: UserWithdrawals
    }
  ]
}
