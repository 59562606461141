import AuthGuard from '@/router/guards/auth'

const ManagerLayout = () => import('../../views/Layout.vue')
const Admins = () => import('../../views/admins/Overview.vue')
const AdminDetails = () => import('../../views/admins/Details.vue')

export const ROUTE_NAMES_ADMINS = Object.freeze({
  OVERVIEW: 'AdminsOverview',
  CREATE: 'AdminsCreate',
  DETAILS: 'AdminsDetails'
});

export const admins = {
  path: '/admins',
  meta: {
    guard: AuthGuard
  },
  component: ManagerLayout,
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_ADMINS.OVERVIEW,
      meta: {
      },
      component: Admins
    },
    {
      path: 'create',
      name: ROUTE_NAMES_ADMINS.CREATE,
      meta: {
      },
      component: AdminDetails
    },
    {
      path: 'details/:id',
      name: ROUTE_NAMES_ADMINS.DETAILS,
      meta: {
      },
      component: AdminDetails
    },
  ]
}
