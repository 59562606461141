import BaseRule from "@/utils/yo-validator/rules/BaseRule";

export default class RuleUppercase extends BaseRule {
  constructor() {
    super();
  }

  validate(fieldName, formData) {
    const upperCase = /^(?=(.*?[A-Z]){1}).{1,}$/
    return super.validateByRegex(fieldName, formData, upperCase);
  }
}
