export const actions = {
  /** Set admins **/
  setAdmins(admins) {
    this.admins = admins;
  },
  /** Set admin details **/
  setAdminDetails(details) {
    this.adminDetails = details;
  },

}
