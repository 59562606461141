<template>
  <button :disabled="disabled"
          :class="{'button-disabled': disabled}"
          :type="type"
          class="button-main d-button">
    <!-- Button text -->
    <span v-if="text">{{ text }}</span>

    <!-- Button icon -->
    <img v-if="icon" :src="icon" class="button-icon" alt=""/>
  </button>
</template>

<script>
export default {
  name: "DButton",
  props: {
    text: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    icon: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">

/* The button with main color background and white text */
.button-main {
  display: flex;
  align-items: center;
  gap: rem(10);
  justify-content: center;
  white-space: nowrap;

  &:hover {
    color: var(--white-text);
  }

  &.button-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.button-black {
  background: var(--dark-main);
  border: rem(2) solid var(--dark-main);
}

.button-border {
  background: transparent;
  border: rem(2) solid var(---white-text);
  color: var(--white-text);
}
</style>
