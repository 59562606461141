import {ERROR_CODES} from '../errorCodes'
import ErrorBase from './ErrorBase'

export default class InvalidCredentials extends ErrorBase {
  constructor(error, message) {
    super(error, message);
    this.code = ERROR_CODES.INVALID_CREDENTIALS;
    this.message = {
      en: 'Incorrect email address or password',
      nl: 'Onjuist e-mailadres of wachtwoord',
    }
  }
}
