import {actions} from './actions';
import {getters} from './getters';
import {apiActions} from './apiActions';
import {defineStore} from "pinia";

export const state = () => ({
  /** Loading spinner **/
  isLoading: false,

  /** Api Error **/
  apiError: '',
});

export const useRootStore = defineStore('root', {
  state,
  getters,
  actions: {...actions, ...apiActions}
});
