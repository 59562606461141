export const COOKIE_KEYS = Object.freeze({
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  EXPIRES_IN: 'expires_in',
});

class Cookies {
  constructor() {
  }

  get(sKey) {
    if (!sKey) {
      return null
    }
    return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
  }

  set({cookieKey, cookieValue, duration = 3600, path = '/', domain = null}) {
    if (!cookieKey || /^(?:expires|max-age|path|domain|secure)$/i.test(cookieKey)) {
      return false
    }
    let sExpires = ''
    if (duration) {
      let end = this.end(duration)
      sExpires = end === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; expires=' + end
    }
    document.cookie =
      encodeURIComponent(cookieKey) + '=' +
      encodeURIComponent(cookieValue) +
      sExpires +
      (domain ? '; domain=' + domain : '') +
      (path ? '; path=' + path : '') +
      (window.location.href.match('https') ? '; secure' : '')
    return true
  }

  remove({cookieKey, path = '', domain = null}) {
    if (!this.has(cookieKey)) {
      return false
    }
    document.cookie = encodeURIComponent(cookieKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' + (domain ? '; domain=' + domain : '') + (path ? '; path=' + path : '')
    return true;
  }

  removeAll() {
    for (let key of this.keys()) {
      this.remove({cookieKey: key, path: '/'});
    }
  }

  keys() {
    let keys = document.cookie.replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:=[^;]*)?;\s*/);
    keys.forEach((key, index) => keys[index] = decodeURIComponent(keys[index]));
    return keys;
  }

  // Checks if a cookie exists.
  has(cookieKey) {
    if (!cookieKey || /^(?:expires|max-age|path|domain|secure)$/i.test(cookieKey)) {
      return false
    }
    return (new RegExp('(?:^|;\\s*)' + encodeURIComponent(cookieKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=')).test(document.cookie)
  }

  end(maxAge) {
    return maxAge === Infinity ? 'Fri, 31 Dec 9999 23:59:59 GMT' : (new Date(maxAge * 1e3 + Date.now())).toUTCString()
  }

  destroy() {
    // clear all cookies except for persistent keys
    this.removeAll();
    // for (let key of this.keys()) {
    //   this.remove(key, '/', `${process.env.VUE_APP_HOST}`);
    // }
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new Cookies()
    }
    return this.instance;
  }
}

export default Cookies.getInstance();
