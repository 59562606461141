import {defineStore} from 'pinia';
import {actions} from './actions';
import {apiActions} from './apiActions';
import {getters} from './getters';

const state = () => ({
  isAuthenticated: false,
  authError: ''
});

export const useAuthStore = defineStore('auth', {
  state,
  getters,
  actions: {...actions, ...apiActions}
})
