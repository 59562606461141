<template>
  <div class="activate-admin-wrapper">
    <div class="activate-form-wrapper">
      <img src="@/assets/img/icons/ic_logo_pw.svg" alt="Logo" class="img-logo" />
      <h3 class="login-title">Welcome!</h3>
      <p class="login-text">Please create a new password for your account.</p>

      <FormWrapper :send-form-data="activateAdmin" :mode="1">
          <template #form-content>

            <PasswordConfirmation
              label-text="New password"
              label-text-confirm="Re-enter password"
              :password-visibility="false" />
        </template>

          <!-- Submit button -->
        <template #button-submit>
            <DButton text="Save" type="submit"/>
            <DLink :router-link="{name: ROUTE_NAMES_AUTH.LOGIN}" 
                  text="Back to login"
                  class="d-link-underline forgot-password-text"/>
        </template>
      </FormWrapper>
    </div>  
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { useAdminsStore } from '@/store/admins'
import { ROUTE_NAMES_AUTH } from "@/router/modules/auth";
import FormWrapper from '@/components/yo-form/FormWrapper.vue'
import PasswordConfirmation from '@/components/yo-form/PasswordConfirmation.vue'
import DButton from '@/components/elements/DButton.vue'
import DLink from '@/components/elements/DLink.vue'
import AuthenticationManager from "@/utils/authenticationManager/AuthenticationManager";
export default {
  name: 'ActivateAdmin',
  components: {
    FormWrapper,
    DButton,
    DLink,
    PasswordConfirmation,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const adminsStore = useAdminsStore()

    // Clear any authenticated data so no auth header is added
    AuthenticationManager.signOut();

    function activateAdmin (data) {
      const id = route.params.id;
      const token = route.params.token;

      const payload = {password: data.password, confirmPassword: data.confirmation}

      adminsStore.activateAdmin(id, token, payload).then((response) => {
        if (response) {
          router.push({name: ROUTE_NAMES_AUTH.LOGIN})
        }
      })

    }

    return {
      activateAdmin,
      ROUTE_NAMES_AUTH
    }
  },
}
</script>

<style lang="scss" scoped>
.activate-admin-wrapper{
  background: var(--main-color);
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.activate-form-wrapper {
  min-height: rem(600);
  width: rem(600);
  border-radius: rem(18);
  background-color: #FFFFFF;
  box-shadow: 0 rem(10) rem(30) rem(-6) rgba(51,53,68,0.05);
  padding: rem(38) rem(129) rem(20);

  display: flex;
  flex-direction: column;
}

.img-logo {
  width: rem(170);
  height: rem(128);
  margin: 0 auto;
}

.login-title {
  margin-bottom: rem(15);
}

.login-text {
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(24);
  margin: 0 0 rem(32);
}

.button-main {
  margin-bottom: rem(22);
}

:deep .form-group {
  margin-bottom: rem(24);
}

:deep .text-error:not(:first-child) {
  position: relative;
  margin-top: rem(4);
}
</style>