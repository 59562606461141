import apiHandler from '@/utils/handlers/ApiHandler';
import {useAuthStore} from '@/store/auth'

export const apiActions = {
  sendLogin(data) {
    const store = useAuthStore();

    // Create the form data:
    const formData = new FormData();
    formData.append('grant_type', 'cms_password');
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    formData.append('client_secret', process.env.VUE_APP_AUTH_CLIENT_SECRET);
    formData.append('username', data.username);
    formData.append('password', data.password);
    // if (data.keepMeLoggedIn) {
    //   formData.append('persistent', data.keepMeLoggedIn);
    // }
    
    // withoutTwoFactor=1 means that the login api call will NOT use 2FA
    return apiHandler.post('auth/login?withoutTwoFactor=1', formData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        store.loginSuccess(Object.assign(data, response.data));
        return response.data;
      }
      return false;
    }).catch(error => {
      store.loginFailure(error);
      return false
    });
  },

  forgotPassword(email) {
    return apiHandler.post('auth/cms/forgot-password', email).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      return false
    });
  },

  resetPassword(payload) {
    const store = useAuthStore();
    const rawData = {
      password: payload.password,
    }

    return apiHandler.post(`auth/cms/reset-password/${payload.userId}/${payload.uuid}`, rawData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      return false
    });
  },
}
