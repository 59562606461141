<template>
  <div>
    <div class="form-group">
      <!-- First password field -->
      <h3 v-if="fieldTitle" class="form-input-title">{{fieldTitle}}</h3>
      <div class="form-input-wrapper">
        <span v-if="labelText" class="form-input-label" :class="{'label-on-change': input}">{{ labelText }}</span>
        <label :for="FIELD_NAME_PASSWORD" class="input-label"></label>
          <input :class="{ 'field-error': apiError || errorMessages.length > 0 }"
                :placeholder="placeholder"
                @input="handleInput"
                :id="FIELD_NAME_PASSWORD"
                :name="FIELD_NAME_PASSWORD"
                v-model="input"
                type="password"
                class="form-input">
      </div>
        <!-- TODO: CHECK WITH DESIGN - IF PASSWORD VISIBILITY IS NEEDED -->
        <!-- <PasswordVisibility v-if="passwordVisibility" id="#password"/> -->
      <!-- <div v-show="apiError || errorMessages.length > 0">
        <p v-if="apiError" class="text-error">{{apiError}}</p>
        <p v-else v-for="(error, index) of errorMessages" :key=index class="text-error">{{error}}</p>
      </div> -->
    </div>
    <div class="form-group">
      <!-- NOTE! Make sure your confirmation field's fieldName is passed here into name prop -->
      <h3 v-if="fieldTitleConfirm" class="form-input-title">{{fieldTitleConfirm}}</h3>
      <div class="form-input-wrapper">  
        <span v-if="labelTextConfirm" class="form-input-label" :class="{'label-on-change': inputConfirmation}">{{ labelTextConfirm }}</span>
        <label :for="FIELD_NAME_CONFIRMATION" class="input-label"></label>
          <input :class="{ 'field-error': apiError || errorMessages.length > 0 }"
                :placeholder="placeholderConfirm"
                @input="handleInput"
                :id="FIELD_NAME_CONFIRMATION"
                :name="FIELD_NAME_CONFIRMATION"
                v-model="inputConfirmation"
                type="password"
                class="form-input">

        <!-- TODO: CHECK WITH DESIGN - IF PASSWORD VISIBILITY IS NEEDED -->
        <!-- <PasswordVisibility v-if="passwordVisibility" id="#confirmation"/> -->
      </div>

      <p class="text-requirements">Use at least 8 characters.</p>

      <div v-show="apiError || errorMessages.length > 0">
        <!-- give priority to api error msg over vue validator error msg, until user inputs again -->
        <p v-if="apiError" class="text-error">{{apiError}}</p>
        <p v-else v-for="(error, index) of errorMessages" :key=index class="text-error">{{error}}</p>
      </div>

      <!-- <div v-if="showPasswordRequirements" class="password-checklist">
        <p class="checklist-title main-text-gray">Wachtwoord moet:</p>
        <ul class="checklist">
          <li v-for="requirement in passwordRequirement" 
              :key="requirement.id"
              class="list-item main-text-gray"
              :class="{'valid-requirement' : (input && inputConfirmation) && !errorMessages.includes(requirement.error), 'invalid-requirement' : (input && inputConfirmation) && errorMessages.includes(requirement.error)}">
              {{requirement.text}}
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
  import {ref, watch, computed} from 'vue';
  import Validator from "@/utils/yo-validator/YoValidator";
  // import {PASSWORD_ERROR} from "@/models/PasswordErrors"
  import PasswordVisibility from '@/components/partials/PasswordVisibility.vue'

  const FIELD_NAME_PASSWORD = 'password';
  const FIELD_NAME_CONFIRMATION = 'confirmation';
  const RULE_NAME_CONFIRMATION = 'passwordConfirm';

  export default {
    name: 'PasswordConfirmation',
    components: {PasswordVisibility},
    props: {
      fieldTitle: {
        type: String,
        required: false
      },
      fieldTitleConfirm: {
        type: String,
        required: false
      },
      placeholder: {
        type: String,
        required: false
      },
      placeholderConfirm: {
        type: String,
        required: false
      },
      debounce: {
        type: Number,
        required: false,
        default: 0
      },
      apiErrorText: {
        required: false,
        type: String
      },
      // These can be used to give an initial value to the input fields.
      valuePassword: {
        type: String,
        required: false
      },
      valueConfirmation: {
        type: String,
        required: false
      },
      showPasswordRequirements: {
        type: Boolean,
        required: false,
      },
      passwordVisibility: {
        type: Boolean,
        required: false,
        default: true
      },
      labelText: {
        type: String,
        required: false
      },
      labelTextConfirm: {
        type: String,
        required: false
      }
    },
    setup(props) {
      /** validator returned error messages **/
      const {errorMessages, handleInput} = Validator.register({
        fieldName: FIELD_NAME_PASSWORD,
        rules: `required|${RULE_NAME_CONFIRMATION}:@${FIELD_NAME_CONFIRMATION}|minPasswordLength:8|uppercase|specialChar`
      });

      const input = ref('');
      const inputConfirmation = ref('');

    // TODO: REMOVE PASSWORD REQUIREMENTS (COMMENTED CODE)

      // const errorExcludingPasswordRequirements = computed(() => {
      //   return errorMessages.value.filter(error => {
      //     return ![PASSWORD_ERROR.SHORT, PASSWORD_ERROR.UPPERCASE, PASSWORD_ERROR.SPECIAL_CHARACTER].includes(error);
      //   });
      // })

      // const passwordRequirement = [
      //   {
      //     id: 1,
      //     text: "Minimaal 8 tekens bevatten",
      //     error: PASSWORD_ERROR.SHORT   
      //   },
      //   {
      //     id: 2,
      //     text: "Een hoofdletter hebben",
      //     error: PASSWORD_ERROR.UPPERCASE 
      //   },
      //   {
      //     id: 3,
      //     text: "Een speciaal teken bevatten",
      //     error: PASSWORD_ERROR.SPECIAL_CHARACTER
      //   } 
      // ]

      /** watch api error text from parent **/
      const apiError = ref('');
      watch(() => props.apiErrorText, (newVal) => {
        apiError.value = newVal.trim();
      });

      return {
        errorMessages,
        apiError,
        handleInput,
        FIELD_NAME_PASSWORD,
        FIELD_NAME_CONFIRMATION,

        input,
        inputConfirmation,
        // passwordRequirement,
        // errorExcludingPasswordRequirements,
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "~@/assets/css/base.mixins";
  @import "~@/assets/css/base.variables";

  .password-checklist {

    .checklist {
      list-style: none;
      padding-left: rem(20);
    }

    .list-item {
      position: relative;
    }

    .list-item:before {
      content: "\2022";
      display: inline-block;
      margin-right: rem(15);
    }
  }

  .list-item.valid-requirement {
    color: var(--green);

    &:before {
      content: '';
      display: inline-block;
      transform: rotate(45deg);
      height: 12px;
      width: 6px;
      border-bottom: 2px solid var(--green);
      border-right: 2px solid var(--green);
    }
  }

  .list-item.invalid-requirement {
    color: var(--blue-main);

    &:before {
      display: inline-block;
      content: "\00d7";
      font-size: rem(22);
      vertical-align: middle;
    }
  }
  .text-requirements{
    position: absolute;
    color: var(--form-text-light);
    font-size: rem(12);
    line-height: rem(24);
    margin: rem(4) 0 0;
  }
  .text-error {
    color: var(--error-text-color);
    font-size: rem(12);
    line-height: rem(13);
    margin-top: rem(26);
  }
</style>
