<template>
  <div class="icon-wrapper">
    <img v-if="!showPassword"
         src= "@/assets/img/icons/ic_hide_pw.svg"
         @click="togglePasswordVisibility" 
         class="input-icon" />
                        
    <img v-else
         src= "@/assets/img/icons/ic_pw_unhide.svg"
         @click="togglePasswordVisibility" 
         class="input-icon" />
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';

export default {
  name: 'PasswordVisibility',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup(props) {
    onMounted(() => {
      getPasswordInputElement();
    })

    let passwordElement;
    const showPassword = ref(false);

    function getPasswordInputElement() {
      passwordElement = document.querySelector(props.id);
    }

    function togglePasswordVisibility() {
      const type = passwordElement.getAttribute("type") === "password" ? "text" : "password";
      passwordElement.setAttribute("type", type);
      showPassword.value = !showPassword.value
    }

    return {
      togglePasswordVisibility,
      showPassword
    }
  }
}
</script>

<style>

</style>