import BaseRule from "@/utils/yo-validator/rules/BaseRule";

export default class RuleAlpha extends BaseRule {
  constructor() {
    super();
  }

  validate(fieldName, formData) {
    // include most of the accent letters
    const regexAlpha = /^[\u0041-\u005A\u0061-\u007A\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\u1E00-\u1EFF]+$/u
    return super.validateByRegex(fieldName, formData, regexAlpha);
  }
}