export default {
  names: {
    username: 'email address',
    email: 'Email',
    password: 'password',
    numberField: 'number',
    profile: 'profile image',
    firstName: 'First name',
    lastName: 'Last name',
    telephoneNumber: 'Telephone number'
  },
  messages: {
    required: {
      default: 'Please fill in your {fieldName}',
      password: 'Please enter a password.'
    },
    alpha: {
      default: '{fieldName} can`t contain numbers.'
    },
    alphaNumeric: {
      default: 'Your {fieldName} can only contain alphabets and numbers'
    },
    numeric: {
      default: 'Incorrect {fieldName} format.'
    },
    email: {
      default: 'Invalid email format.'
    },
    ext: {
      default: 'The extension of your {fieldName} can only be {ext}',
      profile: 'You have upload files of correct types - {ext}'
    },
    max: {
      default: 'Your {fieldName} cannot be bigger than {max}'
    },
    min: {
      default: 'Your {fieldName} cannot be smaller than {min}'
    },
    maxLength: {
      default: 'Your {fieldName} cannot be longer than {maxLength} characters'
    },
    minLength: {
      default: 'Your {fieldName} cannot be shorter than {minLength} characters'
    },
    maxSize: {
      default: 'Your {fieldName} cannot be larger than {maxSize}MB'
    },
    minSize: {
      default: 'Your {fieldName} cannot be smaller than {minSize}MB'
    },
    passwordConfirm: {
      default: 'Passwords do not match.',
    },
    passwordSecurity: {
      default: 'This password change with password not matching security policy',
    },
    uppercase: {
      default: 'Password must contain an uppercase letter'
    },
    specialChar: {
      default: 'Password must contain a special character'
    },
    minPasswordLength: {
      default: 'Password must have a minimum length of 8 characters'
    },
  }
}
