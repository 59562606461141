import AuthGuard from '@/router/guards/auth'

const ManagerLayout = () => import('../../views/Layout.vue')
const Disputes = () => import('../../views/disputes/Overview.vue')
const DisputeDetails = () => import('../../views/disputes/Details.vue')

export const ROUTE_NAMES_DISPUTES = Object.freeze({
  OVERVIEW: 'DisputesOverview',
  DETAILS: 'DisputesDetails'
});

export const disputes = {
  path: '/disputes',
  meta: {
    guard: AuthGuard
  },
  component: ManagerLayout,
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_DISPUTES.OVERVIEW,
      meta: {
      },
      component: Disputes
    },
    {
      path: 'details/:id',
      name: ROUTE_NAMES_DISPUTES.DETAILS,
      meta: {
      },
      component: DisputeDetails
    },
  ]
}
