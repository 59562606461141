<template>
  <div class="form-wrapper">
    <form @submit.prevent="handleSubmit(sendFormData)" novalidate>
      <slot name="form-content"/>
      <slot name="button-submit" :is-invalid="isInvalid"/>
    </form>
  </div>
</template>

<script>
  import Validator from '@/utils/yo-validator/YoValidator';

  export default {
    name: 'FormWrapper',
    props: {
      name: {
        required: false
      },
      sendFormData: {
        required: true
      },
      onFormDataUpdate: {
        type: Function,
        required: false
      },
      mode: {
        default: 1
      }
    },
    setup(props) {
      // get handle submit function
      const {handleSubmit, isInvalid} = Validator.registerForm(props);

      return {
        handleSubmit,
        isInvalid
      };
    }
  }
</script>

<style lang="scss">

@import "../../assets/css/_base.variables.scss";
@import "../../assets/css/_base.mixins.scss";

  $backgroundColor: var(--gray-light);
  $borderColor: var(--grey-medium-4); //
  // $textColor: var(--main-text);
  $textColor: var(--form-text-light);
  $placeholderColor: var(--form-text-light);
  $placeholderColor: var(error-text-color);
  $error-color: var(--error-text-color);
  $input-bg: var(--input-bg-color);

  /* form elements */
  input, select, textarea {
    font-family: var(--font-family);
    border: none;
    width: 100%;

    @include placeholder {
      color: $placeholderColor;
      font-family: var(--font-family);
      font-size: rem(14);
      letter-spacing: 0;
      line-height: rem(20);
    }

    &:focus {
      outline: none;
    }
  }

  label {
    width: 100%;
  }

  input {
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  input[type="date"] {
    &::-webkit-input-placeholder {
      visibility: hidden !important;
    }
  }

  input[type="number"] {
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  input[type="password"] {
    font-family: Verdana;
    letter-spacing: rem(1);
  }

  textarea {
    resize: none;

    /* set the width to 0.1px so it is not visible but the scrolling function still exists */
    &::-webkit-scrollbar {
      width: 0.1px;
    }
  }

  .form-wrapper {
    // height: calc(100% - var(--header-height));
  }

  .form-wrapper form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    height: 100%;
  }

  .form-group{
    margin-bottom: rem(40);
    position: relative;
  }

  .input-label {
    position: relative;
    font-size: rem(12);
  }

  .form-input-title {
    font-size: rem(12);
    font-weight: normal;
    line-height: rem(15);
    margin-bottom: rem(9);
    text-align: left;
  }

  .form-input-wrapper {
    position: relative;
  }

  .form-input-label {
    position: absolute;
    left: rem(21);
    top: 50%;
    transform: translateY(-50%);
    font-size: rem(14);
    color: var(--form-text-light);
    line-height: 1;
    pointer-events: none;
    z-index: z('formLabel');
  }

  .label-on-change {
    font-size: rem(12);
    top: rem(14);

    & ~ .form-input {
      padding-top: rem(20);
    }
  }

  .form-input {
    background-color: var(--input-bg-color);
    border-radius: rem(10);
    color: var(--main-text);
    font-size: rem(14);
    line-height: rem(16);
    height: rem(56);
    padding: 0 rem(21);

    &:focus {
      border-color: var(--input-bg-color);
      background-color: var(--input-bg-color);

      &.field-error {
        border: rem(1) solid var(--error-text-color);
      }
    }
  }

  .form-input-textarea {
    background-color: var(--input-bg-color);
    border-radius: rem(10);
    color: var(--main-text);
    font-size: rem(14);
    line-height: rem(16);
    height: rem(120);
    padding: rem(28) rem(21) rem(24);
    resize: none;
  }

  .form-file-select {
    border: rem(1) solid $borderColor;
    border-radius: rem(8);
    background-color: $backgroundColor;
  }

  /* texts */
  .text-error {
    color: var(--error-text-color);
    font-size: rem(12);
    line-height: rem(13);
    margin-top: rem(5);
    margin-bottom: rem(0);
    position: absolute;

  }

  .text-error-right {
    @extend .text-error;
    text-align: right;
  }

  .field-error {
    border: rem(1) solid var(--error-text-color);
  }

  .read-only-input {
    &:hover {
      cursor: default;
    };
  }

  /** Icons **/
  .input-icon {
    @include position(absolute, $top: 50%, $right: rem(19));
    transform: translateY(-50%);
    height: rem(24);
    width: rem(24);

    &.has-error {

    }
  }

</style>
