import BaseRule from "@/utils/yo-validator/rules/BaseRule";

export default class RuleSpecialCharacters extends BaseRule {
  constructor() {
    super();
  }

  validate(fieldName, formData) {
    const specialChar = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    return super.validateByRegex(fieldName, formData, specialChar);
  }
}
