const Layout = () => import('@/views/auth/Layout');
const Login = () => import('@/views/auth/Login.vue');
const ForgotPassword = () => import('@/views/auth/ForgotPassword.vue');
const ResetPassword = () => import("@/views/auth/ResetPassword.vue");

export const ROUTE_NAMES_AUTH = Object.freeze({
  AUTH: 'Auth',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'ForgotPassword',
  RESET_PASSWORD: 'ResetPassword'
});

export const auth = {
  path: '/auth',
  name: ROUTE_NAMES_AUTH.AUTH,
  component: Layout,
  children: [
    {
      path: 'login',
      name: ROUTE_NAMES_AUTH.LOGIN,
      meta: {},
      component: Login
    },
    {
      path: "forgot-password",
      name: ROUTE_NAMES_AUTH.FORGOT_PASSWORD,
      component: ForgotPassword,
      meta: {
        title: 'Forgot Password',
        backRoute: ROUTE_NAMES_AUTH.LOGIN
      }
    },
    {
      path: "reset-password/:id/:uuid",
      name: ROUTE_NAMES_AUTH.RESET_PASSWORD,
      component: ResetPassword,
      meta: {
        title: 'New Password'
      }
    }
  ]
};
