import {actions} from './actions';
import {getters} from './getters';
import {apiActions} from './apiActions';
import {defineStore} from "pinia";

export const state = () => ({
  admins: null,
  adminDetails: null,
});

export const useAdminsStore = defineStore('admins', {
  state,
  getters,
  actions: {...actions, ...apiActions}
});
