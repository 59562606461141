import apiHandler from '@/utils/handlers/ApiHandler'
import errorHandler from "@/utils/handlers/errorHandler/ErrorHandler";
import AuthDataHandler from "@/utils/storage/AuthDataHandler";

export const actions = {
  loginSuccess(payload) {
    AuthDataHandler.setKeepMeLoggedIn(payload);
    AuthDataHandler.setAccessToken(payload.access_token, payload.expires_in);
    AuthDataHandler.setRefreshToken(payload.refresh_token, 3600 * 24 * 14);
    apiHandler.setApiType(true);
    this.isAuthenticated = true;
    this.authError = '';
  },
  loginFailure(error) {
    this.isAuthenticated = false;
    this.authError = errorHandler.getError(error.response.data).getMessage();
  },
  logout() {
    apiHandler.setApiType(false);
    this.isAuthenticated = false;
    this.authError = '';
  }
}
