import errorHandler from "@/utils/handlers/errorHandler/ErrorHandler";

export const actions = {
  /** Loading spinner **/
  setLoading(isLoading) {
    this.isLoading = isLoading;
  },

  /** API Error **/
  setApiError(error) {
    this.apiError = errorHandler.getError(error.response.data).getMessage();
  },
}
