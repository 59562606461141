<template>
  <a v-if="!routerLink" :href="link" :target="target" class="d-link-base d-link">
    {{ text }}
    <img v-if="icon" :src="icon" class="link-icon" alt=""/>
  </a>

  <p class="disabled-link-text" v-else-if="disabled">{{ text }}</p>
  
  <router-link v-else :to="routerLink" class="d-link-base d-link">
    {{ text }}
  </router-link>
</template>

<script>
export default {
  name: "DLink",
  props: {
    link: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: [String, Number],
      required: true
    },
    target: {
      type: String,
      required: false,
      default: '_blank'
    },
    icon: {
      type: String,
      required: false
    },
    routerLink: {

    },
    disabled: {
      type: Boolean,
      required: false
    }
  }
}
</script>
<style scoped lang="scss">

.d-link-base {
  color: var(--dark-main);
  font-family: var(--font-family);
  font-size: rem(14);
  line-height: rem(20);

  .link-icon {
    height: rem(24);
    margin-left: rem(8);
    vertical-align: middle;
    width: rem(24);
  }

  &:hover {
    text-decoration: underline;
  }
}

.d-link-underline {
  text-decoration: underline;
}

.d-link-white {
  color: #fff;
}

.d-link-blue {
  color: var(--main-color);
  font-size: rem(16);
}

.d-link-bold {
  font-weight: 700;
}

.disabled-link-text {
  text-decoration: none;
}
</style>
