import Axios from 'axios';
import AuthenticationManager from "@/utils/authenticationManager/AuthenticationManager";
import AccessToken from "@/utils/authenticationManager/tokens/AccessToken";
import ApiHeader from './ApiHeader'
import { useRootStore } from '@/store'


const RESPONSE_TYPES = {
  STATUS_OK: 200,
  STATUS_CREATED: 201,
  STATUS_NO_CONTENT: 204,
  STATUS_UNAUTHORIZED: 401
}

class ApiHandler {
  constructor() {
    /** Create the api instance for auth APIs **/
    this.privateApi = Axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/api`,
      // todo: update the properties depending on the back end setup
      // withCredentials: true,
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      // }
    })
    // /** Create the api instance for public APIs - Use if we have both private and public API's **/
    // this.publicApi = Axios.create({
    //   baseURL: `${process.env.VUE_APP_API_URL}/api`,
    //   // todo: update the properties depending on the back end setup
    //   // withCredentials: true,
    //   // headers: {
    //   //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //   // }
    // })
    this.controller = null;
    this.source = null;
    this.requestCount = 0;
    this.api = this.privateApi;
    // this.api = AccessToken.value ? this.privateApi : this.publicApi; // Use this if we have both public and private api's
  }

  // Use this if you need both public & private API's.
  setApiType(isAuth) {
    if (!this.publicApi) return;
    this.api = isAuth ? this.privateApi : this.publicApi;
  }

  setInterceptors() {
    const store = useRootStore();
    this.controller = new AbortController();
    // Add a request interceptor
    this.api.interceptors.request.use(
      async (config) => {
        this.increaseRequestCount();
        if (!AuthenticationManager.isAuthenticated) {
          const refreshed = await AuthenticationManager.refreshTokens();
          if (!refreshed) {
            // throw new Axios.Cancel('Operation cancelled!');
            return config;
          }
        }
        config.headers = ApiHeader.authHeader;
        return config;
      },
      error => {
        this.decreaseRequestCount();
        return Promise.reject(error)
      }
    );
    // Add a response interceptor
    this.api.interceptors.response.use(
      response => {
        this.decreaseRequestCount();
        return response
      },
      error => {
        this.decreaseRequestCount()
        if (AccessToken.value) {
          // We check if the user is logged in because the login failure error is parsed in its own mutation.
          // If the user is logged in, set the api error.
          // store.setApiError(error);
        }
        return Promise.reject(error) // Shows the error in the console.
      }
    );
  }

  sendRequest(method, url, data = {}, params = {}, arrayBufferResponse = false) {
    return this.sendApi(this.api, method, url, data, params, arrayBufferResponse);
  }

  sendApi(instance, method, url, data = {}, params = {}, arrayBufferResponse = false) {
    return instance.request({
      method: method,
      url: url,
      data: data !== undefined ? data : {},
      params: params !== undefined ? params : {},
      responseType: arrayBufferResponse === true ? 'arraybuffer' : undefined,
      signal: this.controller.signal
    })
  }

  get(url, params = {}, responseType = false) {
    return this.sendRequest('GET', url, undefined, params, responseType)
  }

  post(url, data = {}, params = {}, fileResponse = {}) {
    return this.sendRequest('POST', url, data, params, fileResponse)
  }

  patch(url, data = {}, params = {}) {
    return this.sendRequest('PATCH', url, data, params)
  }

  put(url, data = {}, params = {}) {
    return this.sendRequest('PUT', url, data, params)
  }

  delete(url, data = {}) {
    return this.sendRequest('DELETE', url, data)
  }

  increaseRequestCount() {
    const store = useRootStore();
    this.requestCount++;
    if (this.requestCount > 0) {
      store.setLoading(true);
    }
  }

  decreaseRequestCount() {
    const store = useRootStore();
    this.requestCount--;
    if (this.requestCount < 1) {
      store.setLoading(false);
    }
  }

  cancelRequest() {
    this.controller.abort();
  }

  isSuccess(statusCode) {
    return (
      statusCode === RESPONSE_TYPES.STATUS_OK ||
      statusCode === RESPONSE_TYPES.STATUS_CREATED ||
      statusCode === RESPONSE_TYPES.STATUS_NO_CONTENT
    )
  }
}

export default new ApiHandler()
