import AuthGuard from '@/router/guards/auth'

const ManagerLayout = () => import('@/views/Layout.vue')
const Delivery = () => import('@/views/delivery/Overview.vue')
const DeliveryDetails = () => import('@/views/delivery/Details.vue')

export const ROUTE_NAMES_DELIVERY = Object.freeze({
  OVERVIEW: 'DeliveryOverview',
  DETAILS: 'DeliveryDetails'
});

export const delivery = {
  path: '/delivery',
  meta: {
    guard: AuthGuard
  },
  component: ManagerLayout,
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_DELIVERY.OVERVIEW,
      component: Delivery
    },
    {
      path: 'details/:id',
      name: ROUTE_NAMES_DELIVERY.DETAILS,
      component: DeliveryDetails
    },
  ]
}
